import classNames from 'classnames';
import type { ReactNode } from 'react';

/**
 * Heavily inspired from
 * https://codepen.io/chriscoyier/pen/GRJzxEr
 */
export function ScrollNudge({
  children,
  className,
  customRgbBackgroundColor,
}: {
  children: ReactNode;
  className?: string;
  /**
   * Provide RGB value as `red, green, blue`
   * eg: `246, 244, 243`
   */
  customRgbBackgroundColor?: string;
}) {
  return (
    <div
      className={classNames(className, 'scroll-nudge')}
      style={{
        background: `
			linear-gradient(
				rgba(${customRgbBackgroundColor ?? '255, 255, 255'}, 1) 30%,
				rgba(${customRgbBackgroundColor ?? '255, 255, 255'}, 0)
			) center top,

			linear-gradient(
				rgba(${customRgbBackgroundColor ?? '255, 255, 255'}, 0),
				rgba(${customRgbBackgroundColor ?? '255, 255, 255'}, 1) 70%
			) center bottom,

			linear-gradient(
				to bottom, 
				#1A1A1A0A, 
				rgba(0, 0, 0, 0)
			) center top,

			linear-gradient(
				to top, 
				#1A1A1A0A, 
				rgba(0, 0, 0, 0)
			) center bottom
		`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 40px, 100% 40px, 100% 12px, 100% 12px',
        backgroundAttachment: 'local, local, scroll, scroll',
      }}
    >
      {children}
    </div>
  );
}
