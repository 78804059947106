import { Button } from '@carbonfact/ui-components/src/Button';
import Icon from '@carbonfact/ui-components/src/Icon';

type CancelIconButtonProps = {
  onClick: () => void;
};
const CancelIconButton = ({ onClick }: CancelIconButtonProps) => (
  <Button.Default
    aria-label="cancel"
    variant="invisible"
    onClick={onClick}
    className="items-center px-2"
  >
    <Icon
      width={20}
      height={20}
      className="text-carbon-500"
      icon={{
        type: 'solid',
        source: 'hero',
        name: 'XMarkIcon',
      }}
    />
  </Button.Default>
);
export default CancelIconButton;
