import { Button } from '@carbonfact/ui-components/src/Button';
import Container from 'app/components/Container';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

import Sidebar from 'app/components/Navigation/Sidebar';
import { AccountFeatureFlag, useFeatureFlags } from 'app/hooks/useFeatureFlag';
import { compact } from 'lodash';
import { DppSection } from './dpp/DppSection';
import { ModelingSection } from './modeling/ModelingSection';
import Preferences from './preferences/Preferences';
import Targets from './targets/Targets';
import Teammates from './teammates/Teammates';

type SettingsModalProps = {
  onClose: () => void;
};

const SettingsModal = ({ onClose }: SettingsModalProps) => {
  const [activeSection, setActiveSection] = useState('reductionTargets');
  const t = useTranslations();
  const featureFlags = useFeatureFlags();

  return (
    <div className="flex h-full">
      <Sidebar
        title={t('SettingsModal.settings')}
        entries={compact([
          { type: 'title', label: t('SettingsModal.personal') },
          {
            type: 'item',
            label: t('SettingsModal.preferences'),
            onClick: () => setActiveSection('preferences'),
            iconSource: 'hero',
            iconName: 'AdjustmentsVerticalIcon',
            active: activeSection === 'preferences',
          },
          { type: 'divider' },
          { type: 'title', label: t('SettingsModal.company') },
          {
            type: 'item',
            label: t('SettingsModal.teammates'),
            onClick: () => setActiveSection('teammates'),
            iconSource: 'hero',
            iconName: 'UsersIcon',
            active: activeSection === 'teammates',
          },
          {
            type: 'item',
            label: t('SettingsModal.reductionTargets'),
            onClick: () => setActiveSection('reductionTargets'),
            iconSource: 'hero',
            iconName: 'RocketLaunchIcon',
            active: activeSection === 'reductionTargets',
          },
          {
            type: 'item',
            label: t('SettingsModal.modeling'),
            onClick: () => setActiveSection('modeling'),
            iconSource: 'hero',
            iconName: 'BeakerIcon',
            active: activeSection === 'modeling',
          },
          {
            type: 'item',
            label: t('SettingsModal.dpp'),
            onClick: () => setActiveSection('dpp'),
            locked: !featureFlags.includes(AccountFeatureFlag.Dpp),
            hoverMessage: !featureFlags.includes(AccountFeatureFlag.Dpp)
              ? t('LeftMenu.notAvailableInPlan')
              : undefined,
            iconSource: 'local',
            iconName: 'passport',
            active: activeSection === 'dpp',
          },
        ])}
      />
      <Container
        type="MD"
        title={t(`SettingsModal.${activeSection}`)}
        className="overflow-y-auto"
        actions={
          <Button.Icon
            onClick={onClose}
            variant={'secondary'}
            icon={{
              source: 'hero',
              type: 'line',
              name: 'XMarkIcon',
            }}
          />
        }
      >
        {activeSection === 'preferences' && (
          <div>
            <Preferences />
          </div>
        )}
        {activeSection === 'account' && (
          <div>{t('Settings.accountSettings')} </div>
        )}
        {activeSection === 'general' && (
          <div>{t('Settings.generalSettings')} </div>
        )}
        {activeSection === 'teammates' && <Teammates />}
        {activeSection === 'reductionTargets' && <Targets />}
        {activeSection === 'modeling' && <ModelingSection />}
        {activeSection === 'dpp' && <DppSection />}
      </Container>
    </div>
  );
};

export default SettingsModal;
