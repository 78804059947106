'use client';
import MainNavigationPanel from 'app/[locale]/(auth)/root-layout/MainNavigationPanel';
import withAuthWall from 'app/components/AuthWall';
import AutomaticIncidentBanner from 'app/components/AutomaticIncidentBanner';
import useEndpoint from 'app/hooks/useEndpoint';
import { usePathname, useRouter } from 'app/navigation';
import dayjs from 'dayjs';
import Image from 'next/image';
import Link from 'next/link';
import type { ReactNode } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@carbonfact/ui-components/src/Button';
import Icon from '@carbonfact/ui-components/src/Icon';
import { LiveStableSwitcher } from 'app/components/LiveStableSwitcher';
import NavigationItem from 'app/components/Navigation/NavigationItem';
import { ScrollNudge } from 'app/components/ScrollNudge';
import { useCurrentUser } from 'app/context/AuthContext';
import { useTranslations } from 'next-intl';
import * as React from 'react';
import { VisitCustomerModal } from './VisitCustomerModal';
import SettingsActionList from './settings/SettingsActionList';

function AuthLayout({
  children,
  params,
}: {
  children?: ReactNode;
  params: Promise<{ locale: string }>;
}) {
  const { data } = useEndpoint('/settings/language');
  const router = useRouter();
  const pathname = usePathname();
  const language = data?.language;

  const { locale } = React.use(params);
  const t = useTranslations();

  const { user } = useAuth0();
  const userName = user?.name;
  const userEmail = user?.email;

  const carbonfactUser = useCurrentUser();
  const isAdmin = carbonfactUser?.isAdmin;
  const isInternalUser = userEmail?.endsWith('@carbonfact.com');

  const [visitDialogOpen, setVisitDialogOpen] = React.useState(false);
  const { data: metadata } = useEndpoint('/metadata', {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const accountName = metadata?.accountName;
  const latestSync = metadata?.latestSync
    ? dayjs(metadata.latestSync).format(
        `DD/MM [${t('SettingsActionList.at')}] HH:mm`,
      )
    : 'N/A';
  const currentEnvironment = getCurrentEnvironment();

  // If language from the DB is different from the one in localStorage, change it
  if (language && language !== locale) {
    router.replace(pathname, { locale: language });
  }
  dayjs.locale(locale);

  // This endpoint being a bit long to run, we prefer to get it once and for all
  useEndpoint('/product-modeling/config', {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return (
    <div className="flex flex-row w-full">
      {isAdmin && (
        <>
          <Button.Icon
            onClick={() => setVisitDialogOpen(true)}
            icon={{
              source: 'hero',
              type: 'line',
              name: 'BriefcaseIcon',
            }}
            // Rounded button
            className="fixed right-5 top-5 rounded-full bg-gray-700! text-gray-100! w-10 h-10"
          />
          <VisitCustomerModal
            open={visitDialogOpen}
            onClose={() => setVisitDialogOpen(false)}
          />
        </>
      )}
      <div className="hidden z-30 md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
        <div className="flex grow flex-col overflow-y-auto border-r border-gray-200 bg-gray-100">
          <div className="flex shrink-0 items-center justify-between p-6">
            <Link href="/home">
              <Image
                className="h-3 w-32"
                width={128}
                height={12}
                src="/img/carbonfact.svg"
                alt="Carbonfact"
              />
            </Link>
          </div>
          <ScrollNudge
            className="flex flex-1 flex-col overflow-y-auto"
            customRgbBackgroundColor="245, 243, 244"
          >
            <div className="flex flex-1 flex-col">
              <nav className="flex-1 flex-col flex px-4">
                <MainNavigationPanel />
              </nav>
            </div>
          </ScrollNudge>
          <div className="flex flex-col px-4 pt-2">
            {isAdmin && (
              <div className="flex flex-col transition-opacity opacity-30 hover:opacity-100">
                <div className="px-2 pb-1">
                  <h2 className="text-sm font-medium text-gray-800">
                    {'Admin tools'}
                  </h2>
                </div>
                <LiveStableSwitcher />
                <NavigationItem
                  active={false}
                  onClick={() => setVisitDialogOpen(true)}
                >
                  <Icon
                    icon={{
                      source: 'hero',
                      type: 'line',
                      name: 'BriefcaseIcon',
                    }}
                    width={20}
                    height={20}
                  />
                  {'Visit an account'}
                </NavigationItem>

                <hr className="my-2 h-px w-full bg-carbon-200" />
              </div>
            )}
            <NavigationItem active={false} href="https://docs.carbonfact.com">
              <Icon
                icon={{
                  source: 'local',
                  type: 'line',
                  name: 'book',
                }}
                width={18}
                height={18}
              />
              <span className="ml-1">
                {t('SettingsActionList.documentation')}
              </span>
              <Icon
                icon={{
                  source: 'local',
                  type: 'line',
                  name: 'external-link',
                }}
                width={12}
                height={12}
              />
            </NavigationItem>

            {/* Profile dropdown */}
            {accountName &&
              (isInternalUser ? (
                <SettingsActionList
                  displayName={accountName}
                  isInternalAccount
                  environment={currentEnvironment}
                />
              ) : (
                <SettingsActionList
                  displayName={userName || accountName}
                  email={userEmail}
                />
              ))}
            {/* Footer */}
            <div className="flex flex-col gap-y-1 mb-2 px-2">
              <div className="text-left text-xs text-gray-500">
                {t('SettingsActionList.updated')} {latestSync}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto flex flex-1 flex-col md:pl-64 w-full">
        <main className="w-full">
          <AutomaticIncidentBanner />
          <div className="pb-6 md:pt-0 flex justify-center w-full">
            {children}
          </div>
        </main>
      </div>
    </div>
  );
}

export default withAuthWall(AuthLayout);

function getCurrentEnvironment(): 'Staging' | 'Prod' | 'Dev' {
  if (typeof window === 'undefined') {
    return 'Dev';
  }
  const hostname = window.location.hostname;
  if (hostname.includes('carbonfact.com')) {
    if (hostname.includes('live') || hostname.includes('staging')) {
      return 'Staging';
    }
    return 'Prod';
  }
  return 'Dev';
}
