import { withAuthenticationRequired } from '@auth0/auth0-react';
import type { ComponentType, ReactNode } from 'react';

type ComponentWithChildren = ComponentType<{
  children: ReactNode;
  params: Promise<{
    locale: string;
  }>;
}>;

export default function withAuthWall(layoutComponent: ComponentWithChildren) {
  return withAuthenticationRequired(layoutComponent, {
    // Try to get back to the current page after re-auth
    returnTo: () => `${window.location.pathname}${window.location.search}`,
    loginOptions: {
      openUrl: (url) => {
        // Force actual browser navigation to trigger a next.js refetch as we are not
        // a "proper" client-side SPA, so just chaging window.location won't load up
        // pages properly
        window.open(url, '_self');
      },
    },
  });
}
