import { PossibleObjectKindEnum } from '@carbonfact/shared/src/types/platform/corporate';
import type { LocalIconKey } from '@carbonfact/ui-components/src/Icon';

export const CorporateTabIcon: Record<PossibleObjectKindEnum, LocalIconKey> = {
  [PossibleObjectKindEnum.Facility]: 'shop',
  [PossibleObjectKindEnum.Expense]: 'bank-note',
  [PossibleObjectKindEnum.Transport]: 'truck',
  [PossibleObjectKindEnum.BusinessTravel]: 'airplane',
  [PossibleObjectKindEnum.Commute]: 'car',
  [PossibleObjectKindEnum.FacilityEnergyBill]: 'campsite',
};
