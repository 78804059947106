export enum AccountFeatureFlag {
  CarbonAccounting = 'carbon-accounting',
  CfmbReport = 'cfmb-report',
  CompanyModeling = 'company-modeling',
  CsrdReport = 'csrd-report',
  Dpp = 'dpp',
  Ecoscore = 'ecoscore',
  AiChat = 'ai-chat',
  Factories = 'factories',
  ForestLandAgriculture = 'forest-land-agriculture-emissions',
  GhgReport = 'ghg-report',
  Pef = 'pef',
  TextileExchangeReport = 'textile-exchange-report',
  MultiBrands = 'multibrands',
}
