import { Dropdown } from '@carbonfact/ui-components/src/Dropdown';
import { LoadingBar } from '@carbonfact/ui-components/src/Loader';
import { Portal } from '@headlessui/react';
import { Modal } from 'app/components/Modal';
import { useFetchWithAuth } from 'app/context/SWRContext';
import useEndpoint from 'app/hooks/useEndpoint';
import { useState } from 'react';

export function VisitCustomerModal({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const { data } = useEndpoint('/account/list');
  const [loading, setLoading] = useState(false);
  const fetchWithAuth = useFetchWithAuth();

  const handleVisit = async (account: string) => {
    setLoading(true);
    await fetchWithAuth(`/account/${account}/visit`, {
      method: 'PUT',
    });
    // Reload page
    document.location.reload();
  };
  return (
    <Portal>
      <Modal.Popup
        opened={open}
        onClose={onClose}
        title="Visit an account"
        scrollable={false}
      >
        <div className="flex flex-row gap-2 items-center">
          {loading ? (
            <div className="flex flex-row gap-6 items-center w-full text-normal text-carbon-700 font-normal">
              {'Preparing visit...'}
              <LoadingBar
                animationColor="bg-gray-400"
                width="w-40"
                height="h-2"
                className="flex-1"
              />
            </div>
          ) : (
            <>
              <div className="min-w-40">{'Select an account'}</div>
              <Dropdown.Default
                autocomplete
                disabled={loading}
                className="w-full"
                value={null}
                onChange={(e) => handleVisit(e)}
                options={(data || []).map((account) => ({
                  label: `${account.ticker} | ${account.name}`,
                  value: account.slug,
                  description: account.slug,
                }))}
              />
            </>
          )}
        </div>
      </Modal.Popup>
    </Portal>
  );
}
