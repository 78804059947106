import BetaTag from 'app/components/BetaTag';
import { usePathname } from 'app/navigation';
import { useTranslations } from 'next-intl';

import { PossibleObjectKindEnum } from '@carbonfact/shared/src/types/platform/corporate';
import AlphaTag from 'app/components/AlphaTag';
import NavigationDivider from 'app/components/Navigation/NavigationDivider';
import NavigationGroup from 'app/components/Navigation/NavigationGroup';
import NavigationItem from 'app/components/Navigation/NavigationItem';
import NavigationTitle from 'app/components/Navigation/NavigationTitle';
import { AccountFeatureFlag, useFeatureFlags } from 'app/hooks/useFeatureFlag';
import { CorporateTabIcon } from '../corporate/shared/icons';
function isLiveOrDevEnvironment() {
  return (
    process.env.NEXT_PUBLIC_API_BASE_URL !== 'https://stable.api.carbonfact.com'
  );
}

export default function MainNavigationPanel() {
  const t = useTranslations();
  const pathname = usePathname();
  const rootRoute = pathname.split('/')[1];

  const featureFlags = useFeatureFlags();

  return (
    <div>
      <NavigationItem
        href="/home"
        iconName="home"
        active={rootRoute === 'home'}
      >
        {t('LeftMenu.home')}
      </NavigationItem>

      <NavigationDivider />
      <NavigationTitle>{t('LeftMenu.collect')}</NavigationTitle>
      <NavigationItem
        href="/materials"
        iconName="microchip"
        active={rootRoute === 'materials'}
      >
        {t('LeftMenu.materials')}
      </NavigationItem>
      <NavigationItem
        href="/products"
        iconName="styler"
        active={rootRoute === 'products'}
      >
        {t('LeftMenu.products')}
      </NavigationItem>

      <NavigationItem
        href="/factories"
        iconName="factory"
        iconSource="local"
        locked={!featureFlags.includes(AccountFeatureFlag.Factories)}
        hoverMessage={
          !featureFlags.includes(AccountFeatureFlag.Factories)
            ? t('LeftMenu.notAvailableInPlan')
            : undefined
        }
        active={pathname === '/factories'}
      >
        {t('LeftMenu.factories')}
        {featureFlags.includes(AccountFeatureFlag.Factories) && <BetaTag />}
      </NavigationItem>
      <NavigationItem
        href="/rules"
        iconName="ruler"
        active={rootRoute === 'rules'}
      >
        {t('LeftMenu.rules')}
      </NavigationItem>

      {
        <NavigationGroup
          iconName="briefcase"
          active={rootRoute === 'corporate'}
          title={t('LeftMenu.corporate')}
          locked={!featureFlags.includes(AccountFeatureFlag.CarbonAccounting)}
          hoverMessage={
            !featureFlags.includes(AccountFeatureFlag.CarbonAccounting)
              ? t('LeftMenu.notAvailableInPlan')
              : undefined
          }
        >
          <NavigationItem
            href="/corporate/imports"
            iconSource="hero"
            iconName="ArrowUpTrayIcon"
            active={pathname === '/corporate/imports'}
          >
            {t('LeftMenu.imports')} <BetaTag />
          </NavigationItem>
          <NavigationItem
            href="/corporate/facilities"
            iconName={CorporateTabIcon[PossibleObjectKindEnum.Facility]}
            active={pathname === '/corporate/facilities'}
          >
            {t('LeftMenu.facilities')}
          </NavigationItem>
          <NavigationItem
            href="/corporate/expenses"
            iconName={CorporateTabIcon[PossibleObjectKindEnum.Expense]}
            active={pathname === '/corporate/expenses'}
          >
            {t('LeftMenu.expenses')}
          </NavigationItem>
          <NavigationItem
            href="/corporate/transport"
            iconName={CorporateTabIcon[PossibleObjectKindEnum.Transport]}
            active={pathname === '/corporate/transport'}
          >
            {t('LeftMenu.transport')}
          </NavigationItem>
          <NavigationItem
            href="/corporate/business-travel"
            iconName={CorporateTabIcon[PossibleObjectKindEnum.BusinessTravel]}
            active={pathname === '/corporate/business-travel'}
          >
            {t('LeftMenu.businessTravel')}
          </NavigationItem>
          <NavigationItem
            href="/corporate/commute"
            iconName={CorporateTabIcon[PossibleObjectKindEnum.Commute]}
            active={pathname === '/corporate/commute'}
          >
            {t('LeftMenu.commute')}
          </NavigationItem>
        </NavigationGroup>
      }

      <NavigationDivider />
      <NavigationTitle>{t('LeftMenu.measure')}</NavigationTitle>
      <NavigationItem
        href="/explorer"
        iconName="perspective"
        active={rootRoute === 'explorer'}
      >
        <div className="flex flex-row items-center justify-start flex-nowrap gap-2">
          {t('LeftMenu.explorer')}
        </div>
      </NavigationItem>

      <NavigationItem
        href="/views"
        iconName="preset"
        active={rootRoute === 'views'}
      >
        <div className="flex flex-row items-center justify-start flex-nowrap gap-2">
          {t('LeftMenu.views')}
        </div>
      </NavigationItem>

      {(featureFlags.includes(AccountFeatureFlag.AiChat) ||
        isLiveOrDevEnvironment()) && (
        <NavigationItem
          href="/ai-chat"
          iconName="ChatBubbleLeftEllipsisIcon"
          iconSource="hero"
          active={rootRoute === 'ai-chat'}
        >
          {t('LeftMenu.aiChat')} <AlphaTag />
        </NavigationItem>
      )}

      <NavigationDivider />

      <NavigationTitle>{t('LeftMenu.reduce')}</NavigationTitle>
      <NavigationItem
        href="/product-modeling"
        iconName="t-shirt"
        active={rootRoute === 'product-modeling'}
      >
        {t('LeftMenu.productModeling')}
      </NavigationItem>
      <NavigationItem
        href="/company-modeling"
        iconName="trolley"
        locked={!featureFlags.includes(AccountFeatureFlag.CompanyModeling)}
        hoverMessage={
          !featureFlags.includes(AccountFeatureFlag.CompanyModeling)
            ? t('LeftMenu.notAvailableInPlan')
            : undefined
        }
        active={rootRoute === 'company-modeling'}
      >
        {t('LeftMenu.companyModeling')}
        <BetaTag />
      </NavigationItem>

      <NavigationDivider />
      <NavigationTitle>{t('LeftMenu.report')}</NavigationTitle>
      <NavigationItem
        href="/reports"
        iconName="reports"
        active={rootRoute === 'reports'}
      >
        {t('LeftMenu.reports')}
      </NavigationItem>
    </div>
  );
}
