import {
  Field,
  RadioGroup as HUIRadioGroup,
  Label,
  Radio,
} from '@headlessui/react';
import { Fragment } from 'react';

export interface RadioGroupOption<T extends React.Key | null = string> {
  label: string;
  value: T;
  /**
   * Nodes to be rendered below the current option
   * if the value is active
   */
  childrenOnActive?: React.ReactNode;
}

interface RadioGroupProps<T extends React.Key | null = string> {
  options: RadioGroupOption<T>[];
  onChange: (value: T) => void;
  value: T;
  [k: string]: unknown;
}

export function RadioGroup<T extends React.Key | null = string>({
  options,
  onChange,
  value,
  ...props
}: RadioGroupProps<T>) {
  return (
    <HUIRadioGroup value={value} onChange={onChange} {...props}>
      {options.map((option) => (
        <Fragment key={option.value}>
          <Field className="flex items-center gap-2 [&:not(:last-child)]:mb-2">
            <Radio
              value={option.value}
              className="group flex size-5 items-center justify-center rounded-full border border-gray-300 data-[checked]:border-carbon-800 bg-white"
            >
              <span className="invisible size-3 rounded-full bg-carbon-800 group-data-[checked]:visible" />
            </Radio>
            <Label className="text-carbon-800">{option.label}</Label>
          </Field>
          {value === option.value && option.childrenOnActive}
        </Fragment>
      ))}
    </HUIRadioGroup>
  );
}
