import { Dropdown } from '@carbonfact/ui-components/src/Dropdown';
import Loader from '@carbonfact/ui-components/src/Loader';
import MissingDataError from 'app/components/Error/Custom/MissingDataError';
import InlineError from 'app/components/Error/InlineError';
import { useFetchWithAuth } from 'app/context/SWRContext';
import useEndpoint from 'app/hooks/useEndpoint';
import useNetZeroTrajectoryData from 'app/hooks/useNetZeroTrajectoryData';
import { useTranslations } from 'next-intl';
import { useState } from 'react';
import { useSWRConfig } from 'swr';
import { SettingsOptionBlock } from '../components/OptionBlock';

export const ModelingSection = () => {
  const t = useTranslations();
  const { mutate } = useSWRConfig();
  const fetchWithAuth = useFetchWithAuth();

  const {
    data,
    isLoading,
    error: fetchingError,
  } = useEndpoint('/settings/baseline-options');

  const { data: metadata } = useEndpoint('/metadata');

  const { modelingBaselineYear } = useNetZeroTrajectoryData();

  const [error, setError] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState(false);

  if (fetchingError || error) {
    return (
      <InlineError text={error || JSON.stringify(fetchingError)} type="error" />
    );
  }

  if (isLoading) {
    return <Loader type="inline" />;
  }

  if (!data || !data.options || data.options.length === 0) {
    return <MissingDataError />;
  }

  const handleSave = async (year: number) => {
    try {
      setIsSaving(true);
      await fetchWithAuth('/settings/modeling/baseline', {
        method: 'PUT',
        body: JSON.stringify({ year }),
      });
      await mutate('/metadata');
      setIsSaving(false);
    } catch (error) {
      let errorMessage = 'An unexpected error occurred. Please try again.';
      console.error(error);

      if (error instanceof Error) {
        errorMessage = error.message;
      }

      setError(errorMessage);
      setIsSaving(false);
    }
  };

  return (
    <SettingsOptionBlock
      title={t('ModelingSection.baselineYear')}
      description={t('ModelingSection.baselineYearDescription')}
      className="max-w-sm"
    >
      <Dropdown.Default
        align="right"
        loading={isLoading}
        options={data.options.map((year) => ({
          label: year.toString(),
          value: year,
        }))}
        onChange={(e) => void handleSave(e)}
        value={metadata?.modelingBaselineYear || modelingBaselineYear}
        disabled={isSaving}
      />
    </SettingsOptionBlock>
  );
};
