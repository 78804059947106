import { SIZE_LIMIT_IN_BYTES } from '@carbonfact/shared/src/types/platform/settings';
import classNames from 'classnames';
import { uniqueId } from 'lodash';
import { useTranslations } from 'next-intl';
import {
  type ChangeEvent,
  type Dispatch,
  type SetStateAction,
  createRef,
  useState,
} from 'react';
import { UploadButton } from './Button';

type UploadDropzoneProps = {
  acceptedFileExtensions?: string;
  disabled?: boolean;
  className?: string;
  selectedFile: File | undefined;
  setSelectedFile: Dispatch<SetStateAction<File | undefined>>;
};

export const UploadDropzone = ({
  acceptedFileExtensions,
  disabled,
  className,
  selectedFile,
  setSelectedFile,
}: UploadDropzoneProps) => {
  const t = useTranslations();
  const instanceId = uniqueId();

  const ref = createRef<HTMLInputElement>();
  const [error, setError] = useState<string>();

  const onClick = () => {
    if (ref.current === null) return;

    ref.current.click();
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files === null) {
      setSelectedFile(undefined);
      return;
    }

    if (event.target.files[0].size > SIZE_LIMIT_IN_BYTES) {
      setSelectedFile(undefined);
      setError('FILE_OVER_MAX_SIZE');
      return;
    }

    setError(undefined);
    setSelectedFile(event.target.files[0]);
  };

  return (
    <label
      id={`dropzone-${instanceId}`}
      className={classNames(
        'cursor-pointer flex flex-col gap-2 justify-center items-center h-40 rounded-lg border-[1px]',
        {
          'border-carbon-200 border-carbon-200 hover:border-gray-300 hover:bg-gray-50 focus-within:bg-gray-50 focus:bg-gray-50 focus:border-gray-300 focus-within:border-gray-300':
            error === undefined,
          'border-red-200 border-red-200 bg-red-50 hover:border-red-300 hover:bg-red-50 focus-within:bg-red-50 focus:bg-red-50 focus:border-red-300 focus-within:border-red-300':
            error !== undefined,
        },
        className,
      )}
    >
      {selectedFile !== undefined && (
        <p className="text-xs">{selectedFile.name}</p>
      )}
      <UploadButton
        label={
          selectedFile === undefined ? 'Upload file' : 'Upload a different file'
        }
        onClick={onClick}
      />

      {error !== undefined && (
        <p className="text-xs text-red-600 text-center">
          {t(`UploadDropzone.${error}`, {
            maxFileSizeInMb: SIZE_LIMIT_IN_BYTES / 1000 / 1000,
          })}
        </p>
      )}

      <input
        ref={ref}
        id={`dropzone-${instanceId}`}
        type="file"
        accept={acceptedFileExtensions}
        className="hidden"
        disabled={disabled}
        onChange={onChange}
      />
    </label>
  );
};
