import classNames from 'classnames';
import type { ReactNode } from 'react';

type SettingsOptionBlockProps = {
  title: string | ReactNode;
  description?: string;
  children: ReactNode;
  className?: string;
};

export function SettingsOptionBlock({
  title,
  description,
  children,
  className,
}: SettingsOptionBlockProps) {
  return (
    <div className="flex flex-col gap-2">
      <div>
        <p className="font-medium text-sm">{title}</p>

        {description && (
          <p className="text-xs text-carbon-700">{description}</p>
        )}
      </div>
      <div className={classNames('flex flex-1 flew-wrap gap-2', className)}>
        {children}
      </div>
    </div>
  );
}
