export enum DppSettingsImages {
  logo = 'logo',
  brandCover = 'brandCover',
}

export type DppSettingsImagesProp = Partial<
  Record<DppSettingsImages, Express.Multer.File[]>
>;
// 5M bytes = 5000kb = 5mb
export const SIZE_LIMIT_IN_BYTES = 5_000_000;
