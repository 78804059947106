import type { AccountFeatureFlag } from '@carbonfact/shared/src/types/platform/account';
import { useCurrentUser } from 'app/context/AuthContext';
import useEndpoint from 'app/hooks/useEndpoint';

export { AccountFeatureFlag } from '@carbonfact/shared/src/types/platform/account';

export function useFeatureFlag(flag: AccountFeatureFlag): boolean {
  const { data: accountFlags } = useEndpoint('/account/flags');

  return accountFlags?.includes(flag) ?? false;
}

export function useFeatureFlags(): AccountFeatureFlag[] {
  const user = useCurrentUser();
  const { data: accountFlags } = useEndpoint(user ? '/account/flags' : null);

  return accountFlags || [];
}
