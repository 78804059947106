import Icon, { type LocalIconKey } from '@carbonfact/ui-components/src/Icon';
import { LockClosedIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { useState } from 'react';
import { TooltipWrapper } from './NavigationItem';

interface NavigationGroupProps {
  active: boolean;
  title: string;
  children?: React.ReactNode;
  iconName: LocalIconKey;
  hoverMessage?: string;
  locked?: boolean;
}

export default function NavigationGroup({
  active,
  title,
  children,
  iconName,
  locked = false,
  hoverMessage,
}: NavigationGroupProps) {
  const [opened, setOpened] = useState(active);

  return (
    <div>
      <TooltipWrapper hoverMessage={hoverMessage}>
        <div
          className={classNames(
            'flex items-center text-sm px-2 py-[11px] font-medium gap-2 w-full rounded-md',
            opened || active ? 'font-bold' : '',
            locked
              ? 'cursor-not-allowed text-gray-400'
              : 'cursor-pointer hover:bg-carbon-100 text-gray-800',
          )}
          onClick={() => !locked && setOpened(!opened)}
        >
          {locked ? (
            <LockClosedIcon className="shrink-0 h-5 w-5 text-gray-400" />
          ) : (
            <Icon
              icon={{
                source: 'local',
                name: iconName,
                type: active ? 'solid' : 'line',
              }}
            />
          )}
          <div className="grow shrink min-w-0">{title}</div>
          {!locked && (
            <div
              className={classNames(
                'transition-transform',
                opened ? 'rotate-180' : 'rotate-90',
              )}
            >
              <Icon
                width={20}
                height={20}
                icon={{
                  source: 'hero',
                  name: 'ChevronUpIcon',
                  type: 'solid',
                }}
              />
            </div>
          )}
        </div>
        <div
          className={classNames(
            'pl-3 overflow-hidden transition-all',
            opened ? 'max-h-96' : 'max-h-0',
          )}
        >
          {children}
        </div>
      </TooltipWrapper>
    </div>
  );
}
