import type { PlatformApiEndpoints } from '@carbonfact/shared/src/types/platform';
import { Button } from '@carbonfact/ui-components/src/Button';
import InlineError from 'app/components/Error/InlineError';
import InPlaceEdit, {
  type ConsumerInterfaceValues,
} from 'app/components/InPlaceEdit/InPlaceEdit';
import { Toggle } from 'app/components/Toggle';
import { useFetchWithAuth } from 'app/context/SWRContext';
import { useTranslations } from 'next-intl';
import { useState } from 'react';
import { useSWRConfig } from 'swr';
import { SettingsOptionBlock } from '../components/OptionBlock';

type TargetType = PlatformApiEndpoints['/metadata']['response']['targets'][0];

type TargetParams = {
  scope: number;
  type: string;
  value: number;
};

type TargetProps = {
  scope: number;
  targets: TargetType[];
};

const Target = ({ scope, targets }: TargetProps) => {
  const fetchWithAuth = useFetchWithAuth();
  const t = useTranslations();
  const { mutate } = useSWRConfig();
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showInputs, setShowInputs] = useState(false);

  const target = targets.find((target) => target.scope === scope);

  const [targetParams, setTargetParams] = useState<TargetParams>({
    scope: scope,
    type: target?.absoluteValue ? 'absolute' : 'relative',
    value: target?.absoluteValue || target?.relativeValue || 0,
  });

  const handleAddTargetClick = () => {
    setShowInputs(true);
    setTargetParams(getDefaultValue(scope));
  };

  const updateAndSaveTargetParams = (newParams: Partial<TargetParams>) => {
    setTargetParams((prev) => {
      const updatedParams = { ...prev, ...newParams };
      void handleSave(updatedParams);
      return updatedParams;
    });
  };

  const handleSave = async (targetParams: TargetParams) => {
    try {
      setIsSaving(true);

      if (!isInputValid(targetParams)) {
        setError('Invalid input. Please enter a value between 0 and 100.');
        setIsSaving(false);
        return;
      }

      await fetchWithAuth('/settings/target/', {
        method: 'PUT',
        body: JSON.stringify(formatTargetParams(targetParams)),
      });
      await mutate('/settings/targets');
      setIsSaving(false);
    } catch (error) {
      let errorMessage = 'An unexpected error occurred. Please try again.';
      console.error(error);

      if (error instanceof Error) {
        errorMessage = error.message;
      }

      setError(errorMessage);
      setIsSaving(false);
    }
  };

  if (!target && !showInputs) {
    return (
      <div className="flex justify-center items-center p-4">
        <Button.Default onClick={handleAddTargetClick}>
          {t(`Target.add.${scope}`)}
        </Button.Default>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-2 w-full">
      <div className="text-xl text-black font-medium">
        {t(`Target.target.${targetParams.scope}`)}
      </div>

      <SettingsOptionBlock title={t('Target.type')}>
        <Toggle
          options={[
            { label: t('Target.relative'), value: 'relative' },
            { label: t('Target.absolute'), value: 'absolute' },
          ]}
          onChange={(type) => updateAndSaveTargetParams({ type })}
          value={targetParams.type}
        />
      </SettingsOptionBlock>

      <SettingsOptionBlock title={t('Target.value')} className="w-fit flex-col">
        <InPlaceEdit
          inputs={[
            {
              key: 'value',
              type: 'number',
              value: `${targetParams.value}`,
              suffix: '%',
            },
          ]}
          onConfirm={(value: ConsumerInterfaceValues) => {
            updateAndSaveTargetParams({ value: Number(value.value) });
          }}
          disabled={isSaving}
        >
          {({ values, editButton }) => (
            <div className="flex flex-row gap-1 items-center">
              <div className="font-medium">{values.value} %</div>
              {editButton}
            </div>
          )}
        </InPlaceEdit>

        {error && <InlineError className="w-fit" text={error} type="error" />}
      </SettingsOptionBlock>
    </div>
  );
};

const isInputValid = (targetParams: TargetParams) => {
  return targetParams.value > 0 && targetParams.value < 100;
};

const formatTargetParams = (targetParams: TargetParams) => {
  return {
    scope: targetParams.scope,
    relativeValue: targetParams.type === 'relative' ? targetParams.value : null,
    absoluteValue: targetParams.type === 'absolute' ? targetParams.value : null,
  };
};

const getDefaultValue = (scope: number): TargetParams => {
  return {
    scope: scope,
    type: scope === 12 ? 'absolute' : 'relative',
    value: scope === 12 ? 90 : 50,
  };
};

export default Target;
