import ActionList from 'app/components/ActionList/ActionList';
import DialogComponent from 'app/components/Dialog/Dialog';
import { useTranslations } from 'next-intl';
import { useCallback, useMemo, useState } from 'react';

import { Tag } from '@carbonfact/ui-components/src/Tag';
import { useCurrentUser } from 'app/context/AuthContext';
import { useFetchWithAuth } from 'app/context/SWRContext';
import useEndpoint from 'app/hooks/useEndpoint';
import { AccountFeatureFlag, useFeatureFlag } from 'app/hooks/useFeatureFlag';
import SettingsModal from './SettingsModal';

const SettingsActionList = (
  props: { displayName: string } & (
    | {
        isInternalAccount: true;
        environment: 'Staging' | 'Prod' | 'Dev';
      }
    | { isInternalAccount?: false; email: string | undefined }
  ),
) => {
  const { data } = useEndpoint('/brands');
  const fetchWithAuth = useFetchWithAuth();
  const [loading, setLoading] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const t = useTranslations();
  const initial = props.displayName[0].toUpperCase();

  // Temporary until we have some lovely UI
  const carbonfactUser = useCurrentUser();
  const isAdmin = carbonfactUser?.isAdmin;
  const hasMultiBrands = useFeatureFlag(AccountFeatureFlag.MultiBrands);

  const handleBrandSelection = useCallback(
    async (brandId: string | null) => {
      setLoading(true);

      await fetchWithAuth(`/brands/${brandId}/select`, {
        method: 'PUT',
      });

      // Temporay until we invalidate all query like a switch between live/stable: Reload page
      document.location.reload();
    },
    [fetchWithAuth],
  );

  const brandList = useMemo(() => {
    if (!data || data.length < 2 || !isAdmin || !hasMultiBrands) return [];

    return [
      {
        type: 'action' as const,
        label: 'Group view',
        onClick: () => handleBrandSelection(null),
      },
      ...data.map((brand) => ({
        type: 'action' as const,
        label: brand.name,
        onClick: () => handleBrandSelection(brand.id),
      })),
      {
        type: 'divider' as const,
      },
    ];
  }, [data, isAdmin, handleBrandSelection, hasMultiBrands]);

  return (
    <>
      <hr className="mt-2 h-px w-full bg-carbon-200" />
      <ActionList
        className="w-full max-w-full rounded-md hover:bg-carbon-100"
        items={[
          ...brandList,
          {
            type: 'action',
            label: t('SettingsActionList.settings'),
            onClick: () => setIsOpen(true),
          },
          {
            type: 'destructive',
            label: t('SettingsActionList.logout'),
            href: '/logout',
          },
        ]}
      >
        <div className="grid grid-cols-[auto_1fr] gap-3">
          <div className="flex items-center rounded-full text-sm focus:outline-hidden focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
            <span className="sr-only">
              {t('SettingsActionList.openUserMenu')}{' '}
            </span>
            <div className="flex h-10 w-10 cursor-pointer flex-col content-center justify-center rounded-full bg-black text-center text-white">
              <span>{initial}</span>
            </div>
          </div>
          <div className="flex flex-col items-start gap-y-1 max-w-44">
            <div className="text-sm text-left font-medium text-ellipsis w-full overflow-hidden">
              {props.displayName}
            </div>
            {props.isInternalAccount ? (
              <div className="text-xs text-left text-gray-700 text-ellipsis  overflow-hidden">
                <Tag className="bg-blue-100 text-blue-900">
                  {props.environment}
                </Tag>
              </div>
            ) : (
              props.email && (
                <div className="text-xs text-left text-gray-700 text-ellipsis w-full overflow-hidden">
                  {props.email}
                </div>
              )
            )}
          </div>
        </div>
      </ActionList>
      <DialogComponent isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <SettingsModal onClose={() => setIsOpen(false)} />
      </DialogComponent>
      <hr className="mb-2 h-px w-full bg-carbon-200" />
    </>
  );
};

export default SettingsActionList;
