import { Button } from '@carbonfact/ui-components/src/Button';
import Icon from '@carbonfact/ui-components/src/Icon';

type ValidateIconButtonProps = {
  onClick: () => void;
};
const ValidateIconButton = ({ onClick }: ValidateIconButtonProps) => {
  return (
    <Button.Default
      aria-label="validate"
      variant="invisible"
      onClick={onClick}
      className="flex items-center px-2"
    >
      <Icon
        width={20}
        height={20}
        className="text-carbon-500"
        icon={{
          type: 'solid',
          source: 'hero',
          name: 'CheckIcon',
        }}
      />
    </Button.Default>
  );
};
export default ValidateIconButton;
