import type { DppSettingsProperties } from '@carbonfact/shared/src/types/platform/dpp';
import { Button } from '@carbonfact/ui-components/src/Button';
import { Dropdown } from '@carbonfact/ui-components/src/Dropdown';
import { Input } from '@carbonfact/ui-components/src/Form';
import { RadioGroup } from '@carbonfact/ui-components/src/Form/RadioGroup';
import Icon from '@carbonfact/ui-components/src/Icon';
import { Spinner } from '@carbonfact/ui-components/src/Loader';
import { UploadDropzone } from 'app/components/Upload/Dropzone';
import { useFetchWithAuth } from 'app/context/SWRContext';
import useEndpoint from 'app/hooks/useEndpoint';
import { AccountFeatureFlag, useFeatureFlag } from 'app/hooks/useFeatureFlag';
import { compact } from 'lodash';
import { useTranslations } from 'next-intl';
import { useEffect, useMemo, useState } from 'react';
import { SettingsOptionBlock } from '../components/OptionBlock';

export function DppSection() {
  const t = useTranslations();

  const fetchWithAuth = useFetchWithAuth();
  const {
    data,
    isLoading: isSettingsLoading,
    mutate,
  } = useEndpoint('/settings/dpp');
  const { data: ongoingExport, isLoading } = useEndpoint(
    '/dpp/exports/ongoing',
  );
  const hasPef = useFeatureFlag(AccountFeatureFlag.Pef);

  const [brandSettings, setBrandSettings] = useState(data);
  const [isUpdating, setIsUpdating] = useState(false);
  const [hasOngoingExport, setHasOngoingExport] = useState(false);
  const [selectedLogoFile, setSelectedLogoFile] = useState<File>();
  const [selectedCoverFile, setSelectedCoverFile] = useState<File>();

  const loading = isUpdating || isLoading || isSettingsLoading;

  useEffect(() => {
    if (ongoingExport?.ongoingExports.length) {
      setHasOngoingExport(true);
    }
  }, [ongoingExport]);

  useEffect(() => {
    if (!data) return;
    setBrandSettings(data);
  }, [data]);

  const companyProfile = useMemo(() => {
    if (brandSettings === undefined) return;

    if (selectedLogoFile) {
      return URL.createObjectURL(selectedLogoFile);
    }

    if (brandSettings.logoUrl) {
      return brandSettings.logoUrl;
    }
  }, [selectedLogoFile, brandSettings]);

  const coverImage = useMemo(() => {
    if (brandSettings === undefined) return;

    if (selectedCoverFile) {
      return URL.createObjectURL(selectedCoverFile);
    }

    if (brandSettings.dppAboutTheBrandCoverUrl) {
      return brandSettings.dppAboutTheBrandCoverUrl;
    }
  }, [selectedCoverFile, brandSettings]);

  const handleExport = async () => {
    setIsUpdating(true);
    setHasOngoingExport(true);
    await fetchWithAuth('/settings/dpp/export', {
      method: 'POST',
    });
    setIsUpdating(false);
  };

  const handleSave = async () => {
    if (brandSettings === undefined) return;

    setIsUpdating(true);

    try {
      const formData = new FormData();

      if (selectedLogoFile !== undefined) {
        formData.append('logo', selectedLogoFile);
      }

      if (selectedCoverFile !== undefined) {
        formData.append('brandCover', selectedCoverFile);
      }

      for (const [key, value] of Object.entries(brandSettings)) {
        if (value === null) continue;

        formData.append(key, value);
      }

      await fetchWithAuth('/settings/dpp', {
        method: 'PUT',
        body: formData,
        /**
         * We are not using application/json here but rather multipart/form-data
         * so we let the browser infer the correct Content-Type header
         */
        omitContentType: true,
      });

      await mutate();
    } catch (error) {
      //
    }

    setIsUpdating(false);
  };

  if (brandSettings === undefined) {
    return <div>Loading...</div>;
  }

  const dppProductVisibilityOptions = [
    {
      value: 'PUBLIC',
      label: t('Settings.dpp.public'),
    },
    {
      value: 'PRIVATE',
      label: t('Settings.dpp.private'),
    },
  ] as const;

  return (
    <div className="w-full flex flex-col gap-6 justify-between mb-12">
      {/** Visibility */}
      <SettingsOptionBlock
        title={t('Settings.dpp.defaultVisibility')}
        description={t('Settings.dpp.defaultVisibilityDescription')}
      >
        <RadioGroup<(typeof dppProductVisibilityOptions)[number]['value']>
          options={[...dppProductVisibilityOptions]}
          value={brandSettings.productDefaultVisibility}
          onChange={(value) => {
            setBrandSettings({
              ...brandSettings,
              productDefaultVisibility: value,
            });
          }}
        />
      </SettingsOptionBlock>

      {/** Export */}
      <SettingsOptionBlock
        title={t('Settings.dpp.exportAll')}
        description={t('Settings.dpp.exportDescription')}
      >
        {hasOngoingExport ? (
          <div className="text-sm bg-gray-100 p-2 rounded-md items-center flex gap-3">
            <Spinner className="h-2 w-2" />
            {t('Settings.dpp.exportWip')}
          </div>
        ) : (
          <Button.Default
            loading={loading}
            disabled={loading || isUpdating}
            onClick={handleExport}
            className="inline-flex px-4"
          >
            <Icon
              width={20}
              height={20}
              icon={{
                source: 'hero',
                name: 'ArrowDownTrayIcon',
                type: 'solid',
              }}
            />

            {t('Settings.dpp.exportCta')}
          </Button.Default>
        )}
      </SettingsOptionBlock>

      {/** Framework */}
      <SettingsOptionBlock title={t('Settings.dpp.framework')}>
        <Dropdown.Default<DppSettingsProperties['dppFramework']>
          options={compact([
            {
              value: 'GHG',
              label: 'CO2e (PEFCR)',
            },
            hasPef
              ? {
                  value: 'PEF',
                  label: 'PEF (PEFCR)',
                }
              : null,
          ])}
          value={brandSettings.dppFramework}
          onChange={(option) => {
            setBrandSettings({
              ...brandSettings,
              dppFramework: option,
            });
          }}
          disabled={loading || isUpdating}
        />
      </SettingsOptionBlock>

      {/* Company profile */}
      <SettingsOptionBlock title={t('Settings.dpp.companyProfile')}>
        <UploadDropzone
          selectedFile={selectedLogoFile}
          setSelectedFile={setSelectedLogoFile}
          acceptedFileExtensions=".jpg,.jpeg,.png,.svg"
          className="max-w-sm flex-1"
          disabled={loading || isUpdating}
        />
        {companyProfile && (
          <img
            className="h-40 w-40 object-contain rounded-lg"
            src={companyProfile}
          />
        )}
      </SettingsOptionBlock>

      {/** Name */}
      <SettingsOptionBlock title={t('Settings.dpp.name')}>
        <Input
          className="max-w-sm"
          value={brandSettings.dppBrandName ?? ''}
          onChange={(value) => {
            setBrandSettings({
              ...brandSettings,
              dppBrandName: value,
            });
          }}
          disabled={loading || isUpdating}
        />
      </SettingsOptionBlock>

      {/* Cover */}
      <SettingsOptionBlock
        title={t('Settings.dpp.descriptionCover')}
        description={t('Settings.dpp.descriptionCoverDescription')}
      >
        <UploadDropzone
          selectedFile={selectedCoverFile}
          setSelectedFile={setSelectedCoverFile}
          acceptedFileExtensions=".jpg,.jpeg,.png,.svg"
          className="max-w-sm flex-1"
          disabled={loading || isUpdating}
        />
        {coverImage && (
          <img
            className="h-40 max-w-xs flex-1 object-contain rounded-lg"
            src={coverImage}
          />
        )}
      </SettingsOptionBlock>

      {/** Description */}
      <SettingsOptionBlock
        title={
          <>
            {t('Settings.dpp.description')}{' '}
            <span className="text-sm text-carbon-700">
              {t('Settings.dpp.optional')}
            </span>
          </>
        }
        description={t('Settings.dpp.descriptionDescription')}
      >
        <textarea
          className="flex-1 max-w-xl text-sm border-[1px] border-gray-200 rounded-md focus:ring-0 focus:border-gray-300 accent-gray-500 hover:border-gray-300 hover:bg-gray-50 focus-within:bg-gray-50 focus:bg-gray-50 focus:border-gray-300 focus-within:border-gray-300"
          value={brandSettings.dppAboutTheBrand ?? ''}
          onChange={(event) => {
            setBrandSettings({
              ...brandSettings,
              dppAboutTheBrand: event.target.value,
            });
          }}
          disabled={loading || isUpdating}
        />
      </SettingsOptionBlock>

      <Button.Default
        className="mt-2"
        variant="primary"
        onClick={() => {
          handleSave();
        }}
        disabled={loading || isUpdating}
        loading={isUpdating}
      >
        Save
      </Button.Default>
    </div>
  );
}
