import classNames from 'classnames';
import { useTranslations } from 'next-intl';
import NavigationDivider from './NavigationDivider';
import NavigationItem, { type NavigationItemProps } from './NavigationItem';
import NavigationTitle from './NavigationTitle';

type SidebarProps = {
  className?: string;
  entries: (
    | {
        type: 'divider';
      }
    | {
        type: 'title';
        label: string;
      }
    | ({
        type: 'item';
        label: string;
      } & NavigationItemProps)
  )[];
  title?: string;
};

const Sidebar = ({ entries, title, className }: SidebarProps) => {
  const t = useTranslations();

  return (
    <div
      className={classNames(
        'flex flex-col shrink-0 border-r border-gray-100 bg-gray-50 px-4 overflow-y-auto',
        className,
      )}
    >
      <div className="py-6 px-2 text-sm font-medium text-gray-800">{title}</div>
      <nav className="flex-1">
        {entries.map((entry, index) => {
          switch (entry.type) {
            case 'divider':
              return (
                <NavigationDivider
                  key={`divider${
                    // biome-ignore lint/suspicious/noArrayIndexKey: nothing more to do
                    index
                  }`}
                />
              );
            case 'title':
              return (
                <NavigationTitle key={entry.label + entry.type}>
                  {entry.label}
                </NavigationTitle>
              );
            case 'item':
              return (
                <NavigationItem
                  key={entry.label + entry.type}
                  onClick={entry.onClick}
                  iconSource={entry.iconSource}
                  iconName={entry.iconName}
                  hoverMessage={entry.hoverMessage}
                  active={entry.active}
                  locked={entry.locked}
                >
                  {entry.label}
                </NavigationItem>
              );
          }
        })}
      </nav>
    </div>
  );
};

export default Sidebar;
