import { Tag } from '@carbonfact/ui-components/src/Tag';
import { Tooltip } from '@carbonfact/ui-components/src/Tooltip';

export default function AlphaTag() {
  return (
    <Tooltip.Default
      content="⚠️ This feature is in alpha, we're not ready to show it to customers yet. It's only available on Live."
      trigger="hover"
      position="right"
    >
      <Tag
        className="text-[13px] px-1 py-1 leading-none h-[13px] mx-[-3px] bg-orange-100 text-carbon-700 font-semibold relative bottom-[1px]"
        style={{
          transform: 'skew(-10deg)',
        }}
      >
        {'α'}
      </Tag>
    </Tooltip.Default>
  );
}
